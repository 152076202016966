import store from './ReduxStore';
import { RELOAD_REDUX_STATE } from './types';
import { isUserConnectedToMetaMask } from '../services/userService';
const initalState = {};
class StateLoader {

    loadState() {
        try {
            let serializedState = sessionStorage.getItem("ar-redux-state");
            if (serializedState === null) {
                return this.initializeState();
            }
            setTimeout( async () => { 
                // Register all meta mask listeners again on page load.
                await isUserConnectedToMetaMask();
                store.dispatch({
                    type: RELOAD_REDUX_STATE,
                    reduxState: JSON.parse(serializedState) 
              });}, 10);
            return JSON.parse(serializedState);
        }
        catch (err) {
            return this.initializeState();
        }
    }

    saveState(state) {
        try {
            let serializedState = JSON.stringify(state);
            sessionStorage.setItem("ar-redux-state", serializedState);

        }
        catch (err) {
        }
    }

    initializeState() {
        return initalState;
    }
}

export default new StateLoader();