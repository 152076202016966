import { RELOAD_REDUX_STATE } from '../types';
const walletaddressReducer = (state = '', action) => {
    switch(action.type){
        case RELOAD_REDUX_STATE:
            return {
                ...state, ...action.reduxState.walletAddress
              };
        case 'ADDADDRESS':
            return action.payload
        default:
            return state
    }
}
export default walletaddressReducer
