import "./src/styles/global.css";
import "./src/all-custom/custom-gatsby-browser.js";
import { globalHistory } from "@reach/router";

export { default as wrapRootElement } from "./src/redux/ReduxWrapper";

export const onInitialClientRender = () => {
  /**
   * This is a workaround for a bug in Gatsby
   *
   * See https://github.com/gatsbyjs/gatsby/issues/8357 for more details
   */
  globalHistory._onTransitionComplete();
};
