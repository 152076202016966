import {
  logoutAction,
  checkUserLoggedInToMetaMaskAction,
} from "../redux/actions/LoggedAction";
import { store } from "../redux/ReduxStore";
import { UPDATE_CHAIN_STATUS } from "../redux/types";
export const isUserConnectedToMetaMask = async () => {
  try {
    if (window.ethereum) {
      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });
      setTimeout(async () => {
        const eth_chainId = await window.ethereum.request({
          method: "eth_chainId",
        });
        updateCurrentChain(eth_chainId);
        // Unregister listenera - on windlow unload/refresh.
        window.onunload = removeAlreadyAttachedWeb3Listerners;
        addWeb3Listerners();
      },10);

      return accounts[0];
    }
  } catch (err) {
    return false;
  }
};
const removeAlreadyAttachedWeb3Listerners = () => {
  if (!window.web3ListenersRegistered) return;
  window.ethereum.removeListener("chainChanged", metaMaskChainChanged);
  window.ethereum.removeListener("accountsChanged", metaMaskAccountChanged);
  window.ethereum.removeListener("connect", metaMaskConnect);
  window.ethereum.removeListener("disconnect", metaMaskDisconnect);
  window.web3ListenersRegistered = false;
};
const addWeb3Listerners = () => {
  if (window.web3ListenersRegistered) {
    return false;
  }
  window.ethereum.on("chainChanged", metaMaskChainChanged);
  window.ethereum.on("accountsChanged", metaMaskAccountChanged);
  window.ethereum.on("connect", metaMaskConnect);
  window.ethereum.on("disconnect", metaMaskDisconnect);
  window.web3ListenersRegistered = true;
};
const metaMaskConnect = (connectInfo) => {
  console.log(
    "**Artefy,...Connect event triggered with chainId =",
    connectInfo
  );
};
const metaMaskDisconnect = (providerRpcError) => {
  console.log(
    "**Artefy,...disconnect event triggered with chainId =",
    providerRpcError
  );
};
const metaMaskAccountChanged = (accounts) => {
  // if current login is from anyother account then need to logout user from old account and login again with new account here.`);
  // Check current logged-in user
  const artefyUser = sessionStorage.getItem("artefyUser");
  const artefyUserJSON = JSON.parse(artefyUser);
  if (artefyUserJSON?.user && artefyUserJSON.user.username === accounts[0]) {
    console.log(
      "**Artefy,...logged-in user belongs to changes/latest account = " +
        accounts[0]
    );
  } else {
    logoutAction();
    checkUserLoggedInToMetaMaskAction();
  }
};
const metaMaskChainChanged = (chain) => {
  // If user is not currently the logged-in then we need to ignore this event.
  if (!store.getState().isLogged.jwt) return;
  updateCurrentChain(chain);
};

const updateCurrentChain = (chain) => {
  const configuredChains = process.env.GATSBY_DEFAULT_CHAIN_PREFIX.split(",");
  const res = configuredChains.filter((ele) => {
    return ele.startsWith(chain);
  });
  const chainStatus = {
    selectedChain: chain,
    isSupportedChain: true,
  };
  if (res.length > 0) {
    chainStatus.isSupportedChain = true;
  } else {
    chainStatus.isSupportedChain = false;
  }
  store.dispatch({
    type: UPDATE_CHAIN_STATUS,
    chainStatus: chainStatus,
  });
};
export const metamaskSignature = async (publicAddress, nonce) => {
  const params = [publicAddress, nonce];
  const signature = await window.ethereum.request({
    method: "personal_sign",
    params: params,
  });
  return signature;
};
export const ethLogin = async () => {
  try {
    if (window.ethereum) {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      return accounts[0];
    }
    return false;
  } catch {
    return false;
  }
};

export const ethEnabled = async () => {
  return false;
};
