exports.components = {
  "component---src-components-metal-js": () => import("./../../../src/components/metal.js" /* webpackChunkName: "component---src-components-metal-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-all-blogs-js": () => import("./../../../src/pages/blog/allBlogs.js" /* webpackChunkName: "component---src-pages-blog-all-blogs-js" */),
  "component---src-pages-blog-article-js": () => import("./../../../src/pages/blog/article.js" /* webpackChunkName: "component---src-pages-blog-article-js" */),
  "component---src-pages-blog-categories-js": () => import("./../../../src/pages/blog/categories.js" /* webpackChunkName: "component---src-pages-blog-categories-js" */),
  "component---src-pages-blog-category-js": () => import("./../../../src/pages/blog/category.js" /* webpackChunkName: "component---src-pages-blog-category-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marketplace-drops-js": () => import("./../../../src/pages/marketplace/drops.js" /* webpackChunkName: "component---src-pages-marketplace-drops-js" */),
  "component---src-pages-marketplace-index-js": () => import("./../../../src/pages/marketplace/index.js" /* webpackChunkName: "component---src-pages-marketplace-index-js" */),
  "component---src-pages-marketplace-nftcollection-js": () => import("./../../../src/pages/marketplace/nftcollection.js" /* webpackChunkName: "component---src-pages-marketplace-nftcollection-js" */),
  "component---src-pages-marketplace-nftcollections-js": () => import("./../../../src/pages/marketplace/nftcollections.js" /* webpackChunkName: "component---src-pages-marketplace-nftcollections-js" */),
  "component---src-pages-marketplace-product-js": () => import("./../../../src/pages/marketplace/product.js" /* webpackChunkName: "component---src-pages-marketplace-product-js" */),
  "component---src-pages-marketplace-tiercollections-js": () => import("./../../../src/pages/marketplace/tiercollections.js" /* webpackChunkName: "component---src-pages-marketplace-tiercollections-js" */),
  "component---src-pages-nftfaq-js": () => import("./../../../src/pages/nftfaq.js" /* webpackChunkName: "component---src-pages-nftfaq-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */)
}

