import { CHECK_USER_LOGGED_IN_TO_META_MASK, UPDATE_USER_STRAPI_DETAILS,
    UPDATE_USER_AVATAR, LOGOUT_USER, UPDATE_USER_DETAILS, UPDATE_CHAIN_STATUS,
    RELOAD_REDUX_STATE } from '../types';
import { doStrapiLoginOrRegister } from "../actions/LoggedAction";


const initialState = {
    isUserLoggedInToMetaMask: false,
    userAccountId: null,
    jwt: null,
    user: {
        artefyUserName: 'Guest User'
    },
    avatar: {
        url: null//'https://media.istockphoto.com/vectors/male-face-silhouette-or-icon-man-avatar-profile-unknown-or-anonymous-vector-id1087531642?k=20&m=1087531642&s=612x612&w=0&h=D6OBNUY7ZxQTAHNVtL9mm2wbHb_dP6ogIsCCWCqiYQg='
    },
    error: null,
    chainStatus: {
        selectedChain: '',
        isSupportedChain: true
    }
  };

const loggedReducer = (state = initialState, action) => {
    switch(action.type){
        case RELOAD_REDUX_STATE:
            return {
                ...state, ...action.reduxState.isLogged
              };
        case CHECK_USER_LOGGED_IN_TO_META_MASK+'/pending':
            return {
                ...state
              };
        case CHECK_USER_LOGGED_IN_TO_META_MASK+'/fulfilled':
            if(state.userAccountId !== action.payload.userAccountId) {
                doStrapiLoginOrRegister(state.userAccountId, action.payload.userAccountId);
                return {
                    ...state,
                    isUserLoggedInToMetaMask: action.payload.isUserLoggedInToMetaMask,
                    userAccountId: action.payload.userAccountId
                };
            }
               
            else
                return {...state};
        case UPDATE_USER_STRAPI_DETAILS:
            return {
                ...state,
                jwt: action.data.jwt,
                user: action.data.user
            }
        case UPDATE_USER_DETAILS:
            return {
                ...state,
                user: action.user
            }
        case UPDATE_USER_AVATAR:
            return {
                ...state,
                avatar: action.avatar?action.avatar:initialState.avatar
            }
        case UPDATE_CHAIN_STATUS:
            return {
                ...state,
                chainStatus: action.chainStatus?action.chainStatus:initialState.chainStatus
            }
        case LOGOUT_USER:
            return {
                ...initialState
            }
        default:
            return state;
    }
}
export default loggedReducer
