import React from "react";
import { Provider } from "react-redux";
import store from "./ReduxStore";
import { AppContextProvider } from "../context/AppContext";

const ReduxWrapper = ({ element }) => (
  <Provider store={store}>
    <AppContextProvider>{element}</AppContextProvider>
  </Provider>
);

export default ReduxWrapper;
