import {
  CHECK_USER_LOGGED_IN_TO_META_MASK,
  UPDATE_USER_STRAPI_DETAILS,
  UPDATE_USER_AVATAR,
  LOGOUT_USER,
} from "../types";
import {
  isUserConnectedToMetaMask,
  metamaskSignature,
} from "../../services/userService";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import store from "../ReduxStore";
import { navigate } from "gatsby";
import appConstants from "../../constants/appConstants";

const SHARED_STRAPI_BASE_API_URL = `${process.env.GATSBY_SHARED_STRAPI_BASE_URL}/api`;
const sharedStrapi_userAvatarUrl = (userId) =>
  `${SHARED_STRAPI_BASE_API_URL}/users/${userId}?populate[0]=avatar,notification`;

export const checkUserLoggedInToMetaMaskAction = createAsyncThunk(
  CHECK_USER_LOGGED_IN_TO_META_MASK,
  async () => {
    const res = await isUserConnectedToMetaMask();
    return { isUserLoggedInToMetaMask: res ? true : false, userAccountId: res };
  }
);
export const doStrapiLoginOrRegister = async (
  metaMaskAccountId_old,
  metaMaskAccountId,
  retryLogin = true
) => {
  // metaMaskAccountId = metaMaskAccountId+'_2';
  if (metaMaskAccountId_old) {
    // && !metaMaskAccountId
    logoutAction();
  }
  if (metaMaskAccountId && metaMaskAccountId.startsWith("0x")) {
    outLog("Login user " + metaMaskAccountId);
    // get or register user by publicAddress.
    try {
      const user = await axios.get(
        `${SHARED_STRAPI_BASE_API_URL}/metamaskauth/${metaMaskAccountId}`
      );

      // Now we need to ask for signing in nonce.
      try {
        // If user found in sessionStorage - no need for signature.
        // if(updateStoreFromsessionStorage()) {
        //     return;
        // }
        // Else/otherwise we don't have data in local storage - get it.
        const signature = await metamaskSignature(
          user.data.username,
          user.data.nonce
        );
        // alert make a call to get jwt.
        const authUser = await axios.post(
          `${SHARED_STRAPI_BASE_API_URL}/metamaskauth`,
          {
            publicAddress: metaMaskAccountId,
            signature: signature,
          }
        );
        // Here User has been registered or we got from strapi.
        // If the user hasn't updated the profile - take user to register.
        if (user.data.email.indexOf(appConstants.default_dummay_email) !== -1) {
          navigate("/register");
        }
        window.sessionStorage.setItem(
          appConstants.artefyUser,
          JSON.stringify(authUser.data)
        );
        updateStoreFromsessionStorage();
      } catch (error) {
        logoutAction();
      }
    } catch (error) {
      alert("Something went wrong while fetch/register user " + error);
    }
  } else {
    // alert('Got wrong metamask account id = '+metaMaskAccountId);
  }
};

export const logoutAction = () => {
  window.sessionStorage.removeItem(appConstants.artefyUser);
  setTimeout(() => {
    store.dispatch({
      type: LOGOUT_USER,
    });
  }, 200);
};

const updateUserDetailsInStore = (result) => {
  store.dispatch({
    type: UPDATE_USER_STRAPI_DETAILS,
    data: {
      jwt: result.data.jwt,
      user: result.data.user,
    },
  });
};
const updateUserAvatarInStroe = (id, jwt) => {
  if (!id || !jwt) return;
  // fetch user Avatar.
  axios
    .get(sharedStrapi_userAvatarUrl(id), {
      headers: { Authorization: `Bearer ${jwt}` },
    })
    .then((res) => {
      outLog("User Avatar::", res);
      // Update User avatar.
      store.dispatch({
        type: UPDATE_USER_AVATAR,
        avatar: res.data.avatar,
      });
      // Update local storage
      const updatedLocalData = {
        jwt: jwt,
        user: res.data,
      };
      window.sessionStorage.setItem(
        appConstants.artefyUser,
        JSON.stringify(updatedLocalData)
      );
      // Update Store From local storage.
      updateStoreFromsessionStorage(false);
    })
    .catch((err) => {
      outLog("user logs in but unable to get user avatar. error=", err);
    });
};
const updateStoreFromsessionStorage = (fetchAvatar = true) => {
  const local_userLoginDetails = window.sessionStorage.getItem(
    appConstants.artefyUser
  );
  if (local_userLoginDetails) {
    const localUserDetails = JSON.parse(local_userLoginDetails);
    if (localUserDetails.jwt) {
      updateUserDetailsInStore({
        data: {
          jwt: localUserDetails.jwt,
          user: localUserDetails.user,
        },
      });
      if (fetchAvatar) {
        updateUserAvatarInStroe(localUserDetails.user.id, localUserDetails.jwt);
      }
      checkUserLoggedInToMetaMaskAction();
      return true;
    }
  }

  return false;
};
const outLog = (msg, obj) => {
  console.log(`**Artefy:: ${msg} `, obj);
};

export const signIn = () => {
  return {
    type: "SIGN_IN",
  };
};
