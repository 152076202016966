import React, { useState } from "react";

const defaultState = {
  categoryFilter: "All",
  sortFilter: "Latest",
  sortedArticles: [],
  scrollToDropId: "",
  editProfileState: false,
  updateCategoryFilter: () => null,
  updateSortFilter: () => null,
  updateSortedArticles: () => null,
  updateScrollToDropId: () => null,
  updateEditProfileState: () => null,
};

const AppContext = React.createContext(defaultState);

export const AppContextProvider = ({ children }) => {
  const [categoryFilter, setCategoryFilter] = useState("All");
  const [sortFilter, setSortFilter] = useState("Latest");
  const [sortedArticles, setSortedArticles] = useState([]);
  const [scrollToDropId, setScrollToDropId] = useState("");
  const [editProfileState, setEditProfileState] = useState(false);
  return (
    <AppContext.Provider
      value={{
        categoryFilter,
        sortFilter,
        sortedArticles,
        scrollToDropId,
        editProfileState,
        updateCategoryFilter: setCategoryFilter,
        updateSortFilter: setSortFilter,
        updateSortedArticles: setSortedArticles,
        updateScrollToDropId: setScrollToDropId,
        updateEditProfileState: setEditProfileState,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
