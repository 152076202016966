// import { createStore, combineReducers, compose } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import walletaddressReducer from './reducers/walletAddress';
import loggedReducer from './reducers/isLogged';
import stateLoader from "./StateLoader";

const initalState = {};
export const store = configureStore({
  reducer: {
    walletAddress: walletaddressReducer,
    isLogged: loggedReducer
  }
}, stateLoader.loadState());

store.subscribe(() => {
  stateLoader.saveState(store.getState());
});


// const enhancers = compose(
//   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// );

// const store = createStore(allReducers, initalState);

export default store;
